<template>
  <div>
    <el-container>
      <el-header height="100%">
        <component :is="headerComName" />
      </el-header>
      <el-main class="CityH2">
        <div class="w">
          <div class="kr-content">
            <div
              v-loading="detailLoading"
              class="articleBox"
            >
              <div
                v-if="detailData"
                class="articleBody"
              >
                <div class="kr-s1">
                  <div class="kr-t1">{{ detailData.title }}</div>
                  <div class="kr-t2">{{ detailData.stateDate | format('yyyy.MM.dd') }}</div>
                </div>
                <div class="kr-s2">
                  <article v-if="detailData.content" v-html="detailData.content"></article>
                  <video v-if="detailData.video" width="100%" controls preload muted :src="detailData.video"></video>
                </div>
                <div v-if="detailData.summary" class="kr-s5">
                  <div class="kr-t1">{{ $i18n.locale === 'en' ? 'Summary' : '简介' }}：</div>
                  <div class="kr-t2">{{ detailData.summary }}</div>
                </div>
                <template v-if="detailData.videoList && detailData.videoList.length">
                  <div class="kr-s3">
                    <div class="kr-col1">
                      <img
                        class="g1"
                        :src="g2ImgPath"
                        alt=""
                      >
                    </div>
                    <div class="kr-col2">
                      <div class="kr-btn1" @click="handleToVideoList">{{ $i18n.locale === 'en' ? 'more' : '更多' }}<img
                          class="kr-icon1"
                          src="../../assets/images/H/more-horizontal.svg"
                        ></div>
                    </div>
                  </div>
                  <div class="kr-s4">
                    <div class="videoBox">
                      <div
                        v-for="item in detailData.videoList"
                        :key="item.key"
                        class="videoItem"
                        @click="handleToVideoDetail(item)"
                      >
                        <div class="kr-col1">
                          <div class="g1Box">
                            <video
                              class="g1"
                              playsinline
                              preload="metadata"
                            >
                              <source
                                :src="item.video + '#t=0.1'"
                                type="video/mp4"
                              >
                            </video>
                          </div>
                        </div>
                        <div class="kr-col2">
                          <div class="kr-t1">{{ item.title }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <gotop></gotop>
      </el-main>
      <el-footer>
        <component :is="footerComName" />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Gotop from '../../components/gotop'
import { mapGetters } from 'vuex'
import axios from 'axios'


export default {
  components: {
    Gotop
  },
  computed: {
    ...mapGetters(['devicePathStr', 'langPathStr', 'headerComName', 'footerComName']),
    g2ImgPath() {
      return require(`../../assets/images/H/g2${this.langPathStr}${this.devicePathStr}.png`)
    }
  },
  data() {
    return {
      detailLoading: false,
      detailData: null
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      this.detailLoading = true
      try {
        const res = await axios.get(`web/activity${ this.$route.meta.listType }/` + this.$route.params.detailId)
        if (res && res.data && res.data.code === 200) {
          this.detailData = res.data.data
          localStorage.setItem('kr-toId', this.detailData.toId)
        }
      } catch (error) {
        console.log(error)
      }
      this.detailLoading = false
    },
    handleToVideoDetail(row) {
      console.log(row);
      this.$router.push({
        name: this.$i18n.locale === 'en' ? 'VideoDetaile' : 'VideoDetail',
        params: {
          detailId: row.id
        }
      })
    },
    handleToVideoList() {
      this.$router.push({
        name: this.$i18n.locale === 'en' ? 'VideoListe' : 'VideoList'
      })
    }
  }
}
</script>

<style
  scoped
  lang="less"
>
.CityH2 {
  font-family: Microsoft YaHei, Microsoft YaHei;

  .kr-content {
    padding-top: 110px;
    padding-bottom: 60px;
    max-width: 800px;
    margin: auto;
    position: relative;

    .articleBox {
      min-height: 320px;

      .articleBody {
        .kr-s1 {
          border-bottom: 2px solid #E8E8E8;
          text-align: left;

          .kr-t1 {
            font-size: 40px;
            color: #000000;
            line-height: 56px;
          }

          .kr-t2 {
            padding-top: 20px;
            padding-bottom: 40px;
            font-size: 16px;
            color: #A2A2A2;
            line-height: 20px;
          }
        }

        .kr-s2 {
          padding-top: 40px;
          padding-bottom: 32px;
          text-align: left;

          /deep/ video {
            width: 100%;
            height: auto;
          }
        }

        .kr-s3 {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          row-gap: 20px;

          .kr-col1 {
            width: 740px;
            display: flex;

            .g1 {
              width: 330px;
            }
          }

          .kr-col2 {
            flex: auto;

            .kr-btn1 {
              width: 100%;
              height: 28px;
              display: flex;
              align-items: center;
              justify-content: center;
              column-gap: 4px;
              border-radius: 4px 4px 4px 4px;
              border: 1px solid #A2A2A2;
              font-size: 14px;
              color: #A2A2A2;
              cursor: pointer;

              .kr-icon1 {
                height: 2px;
              }
            }
          }
        }

        .kr-s4 {
          padding-top: 20px;
          padding-bottom: 20px;
          overflow-x: auto;

          /* 滚动条样式 */
          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }

          /* 滚动条轨道 */
          &::-webkit-scrollbar-track {
            background: #f2f2f2;
            border-radius: 4px;
          }

          /* 滚动条滑块 */
          &::-webkit-scrollbar-thumb {
            background: #ddd;
            border-radius: 4px;
          }

          /* 滚动条滑块悬停样式 */
          &::-webkit-scrollbar-thumb:hover {
            background: #ccc;
          }

          &::-webkit-scrollbar-track-piece {
            background-color: white;
          }

          .videoBox {
            display: flex;
            column-gap: 20px;
            min-width: min-content;

            .videoItem {
              width: 200px;
              background: #FFFFFF;
              border-radius: 4px 4px 4px 4px;
              border: 1px solid #E8E8E8;
              cursor: pointer;

              .kr-col1 {
                width: 100%;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                /* border: 1px solid #707070; */
                overflow: hidden;

                .g1Box {
                  position: relative;
                  /* padding-right: 100%; */
                  /* padding-bottom: 100%; */

                  .g1 {
                    /* position: absolute; */
                    width: 100%;
                    pointer-events: none;
                  }
                }
              }

              .kr-col2 {
                overflow: hidden;
                padding: 20px;
                text-align: left;

                .kr-t1 {
                  font-size: 14px;
                  color: #000000;
                  line-height: 20px;
                  min-height: 40px;
                  max-height: 40px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
              }
            }
          }
        }

        .kr-s5 {
          display: flex;
          column-gap: 12px;
          text-align: left;

          .kr-t1 {
            font-size: 18px;
            color: #A2A2A2;
            line-height: 30px;
            white-space: nowrap;
          }

          .kr-t2 {
            font-size: 18px;
            color: #222222;
            line-height: 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width:1024px) {
  .CityH2 {
    .kr-content {
      padding-top: 60px;

      .articleBox {
        .articleBody {
          .kr-s1 {
            .kr-t1 {
              font-size: 32px;
              line-height: 42px;
            }

            .kr-t2 {
              padding-bottom: 20px;
            }
          }

          .kr-s2 {
            padding-top: 20px;
          }

          .kr-s3 {
            .kr-col1 {
              width: calc(100% - 60px);

              .g1 {
                width: 227px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style scoped>
.el-header {
  padding: 0;
}

.el-main {
  margin-top: 0;
  padding: 0;
  overflow: initial;
}

.el-main .w {
  padding: 15px;
}
</style>
